<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0 font-weight-bolder">
          미팅룸 상세
        </h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->

      <!-- Body -->
      <b-form
        @submit.prevent
      >
          <b-table
          ref="refInvoiceListTable"
          :items="items"
          responsive
          :fields="metSettingColums"
          show-empty
          empty-text="조회된 목록이 없습니다."
          class="custom position-relative table-setting text-left mb-0"
          >
          </b-table>
      </b-form>
    </template>
  </b-sidebar>
</template>
<style>
.custom.table-setting th div{
  display: none;
}
.custom.table-setting table thead th:first-child{
  width: 29%;
}
.custom.table-setting table thead th{
  padding: 0;
}
.custom.table-setting table tbody td{
  padding-left: 1.5rem !important;
  text-align: left;
}
</style>
<script>
import {
  BSidebar, BForm, BTable, VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    BSidebar, BForm, BTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: Object,
    default: () => [],
  },
  data() {
    return {
      data: {
      }, 
      metSettingColums: [
        { key: 'ce_ver', label: '미팅룸 상세'},
        { key: 'commend', label: ''},
      ],
      items: [
        { 
          'ce_ver': '미팅룸 이름',
          'commend': this.item.name,
        },
        {
          'ce_ver': '미팅룸 URI',
          'commend': this.item.uri,
        },
        {
          'ce_ver': '미팅룸 번호',
          'commend': this.item.callId,
        },
        {
          'ce_ver': '비밀번호',
          'commend': this.item.passcode,
        },
        {
          'ce_ver': '기본 레이아웃',
          'commend': this.item.defaultLayout,
        },
      ],
      passcode: 'null',
      defaultLayout: 'null',
    }
  },
  watch: {
    item() {
      if(!this.item.passcode){
        this.passcode = 'null'
      }else{
        this.passcode = this.item.passcode
      }
      if(!this.item.defaultLayout){
        this.defaultLayout = 'null'
      }else{
        this.defaultLayout = this.item.defaultLayout
      }
      this.items = [
        { 
          'ce_ver': '미팅룸 이름',
          'commend': this.item.name,
        },
        {
          'ce_ver': '미팅룸 URI',
          'commend': this.item.uri,
        },
        {
          'ce_ver': '미팅룸 번호',
          'commend': this.item.callId,
        },
        {
          'ce_ver': '비밀번호',
          'commend': this.passcode,
        },
        {
          'ce_ver': '기본 레이아웃',
          'commend': this.defaultLayout,
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style>

</style>
