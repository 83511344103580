<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    
    <div class="m-1">
      <!-- Table Top -->
      <b-row>
        <!-- 타이틀 -->
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
          class="clearfix"
        >
          <h4 class="float-left font-weight-bolder mt-8px">미팅룸 조회</h4>
          <div class="justify-content-center spinner-custom float-right ml-1" :class="{ 'd-none' : active }">
              <b-spinner
                v-for="variant in variants"
                :variant="variant"
                :key="variant"
              ></b-spinner>
          </div>
          <b-form-select
              class="float-right col-md-2"
              v-model="selectArea"
              size=""
              :options="this.areaOption"
              placeholder="권역"
            />
            
        </b-col>
        <!-- 타이틀 END -->
      </b-row>

    </div>
    <div class="pl-1 pr-1">
    <!-- table -->
        <vue-good-table
        :columns="metRoomListColumns"
        :rows="this.items"
        :rtl="direction"
        v-dragscroll="{ target: '.vgt-responsive' }"
        :search-options="{
            enabled: false,
          }"
        :sort-options="{
          enabled: false,
        }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        theme="my-theme"
        class="table table-light"
        >
        <div slot="emptystate" class="text-center">
          조회된 목록이 없습니다.
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >
            <!-- Column: Name -->
            <div v-if="props.column.field === 'sn'" class="text-nowrap">
               {{ props.row.originalIndex + 1 }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterName'" class="text-nowrap">
                {{ props.formattedRow.tbCmsMasterName }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterIp'" class="text-nowrap">
                {{ props.formattedRow.tbCmsMasterIp }}
            </div>
            <div v-if="props.column.field === 'name'" class="text-nowrap">
                {{ props.formattedRow.name }}
            </div>
            <div v-if="props.column.field === 'uri'" class="text-nowrap">
                {{ props.formattedRow.uri }}
            </div>
            <div v-if="props.column.field === 'callId'" class="text-nowrap">
                {{ props.formattedRow.callId }}
            </div>
            <div v-if="props.column.field === 'cospaceGuid'" class="text-nowrap">
                {{ props.formattedRow.cospaceGuid }}
            </div>
        </template>
        
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-end flex-wrap">
            <div>
                <b-pagination
                :value="1"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="m-2"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
      </div>
    <!-- 미팅룸 조회 리스트 -->
    <met-room-detail :item="this.MetRoomItem"></met-room-detail>
  </b-card>
  
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormSelect, BButton, VBToggle, BSpinner,
  BPagination,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

import MetRoomDetail from './MetRoomDetail.vue'


export default {
  directives: {
    Ripple,
    dragscroll,
    'b-toggle': VBToggle,
  },
  components: {
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormSelect,
    BButton,
    BSpinner,
    BPagination,

    MetRoomDetail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: true,
    },
    total: {
      type: String,
      default: '0',
    },
    areaOption: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      data: {
      },
      metDetailUrl: '/api/v1/meeting/',
      variants: ['primary'],
      metRoomListColumns: [
        { field: 'sn', label: '번호', width: '55px'},
        { field: 'tbCmsMasterName', label: '권역명', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역명', // placeholder for filter input
          },
        },
        { field: 'tbCmsMasterIp', label: '권역 IP', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역 IP', // placeholder for filter input
          },
        },
        { field: 'name', label: '이름', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '이름', // placeholder for filter input
          },
        },
        { field: 'uri', label: 'Uri', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Uri', // placeholder for filter input
          },
        },
        { field: 'callId', label: 'Call ID', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Call ID', // placeholder for filter input
          },
        },
        { field: 'cospaceGuid', label: 'Cospace Guid', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Cospace Guid', // placeholder for filter input
          },
        }
      ],
      searchTerm: '',
      MetRoomItem: {},
      pageLength: 10,
      dir: false,
      searchItem: '',
      perPage: 10,
      currentPage: 1,
      selectArea: 0,
    }
  },
  watch: {
    currentPage(val){
      this.setOffset(val)
    },
    selectArea(val){
      this.selectArea = val
      this.$emit("getSn", this.selectArea)
    }
  },
  mounted(){
    // this.setCmsServer()
    /**
     * CMS 위젯 이동 서버 자동선택
     * @created 2021-04-16
     * @author 박재헌
     * @param {number} $route.query.selectArea
     * 서버순번으로 서버선택 셀렉트 val 자동 선택
     */
    if(this.$route.query.selectArea != undefined || this.$route.query.selectArea != null){
      this.selectArea = this.$route.query.selectArea
    }else{
      
    }
    this.$emit("getSn", this.selectArea)
  },
  methods: {
    /**
     * CMS 서버 SET
     * @created 2021-04-20
     * @author 박재헌
     * @param {Array} $store.state.cmsServerList 웹소켓 cmsSeverList 데이터
     * @comment areaOption {Array} 에 각 cmsServerList 세팅 후 셀렉트 박스 세팅
     */
    setCmsServer(){
      this.areaOption = []
      let setList = this.$store.state.cmsServerList
      this.selectArea = setList[0].sn
      for (let index = 0; index < setList.length; index++) {
        const element = setList[index];
        this.areaOption.push({value: element.sn, text: element.name, ip: element.ip});
      }
    },
    /**
     * CMS 통합검색
     * @created 2021-04-20
     * @author 박재헌
     * @param {String} params.searchTerm 검색 Input 박스 내용
     * @comment 부모 Calls 컴포넌트에 통합검색 문자열 params.searchTerm 전송
     */
    onSearch(params) {
      this.searchItem = params.searchTerm
      this.$emit('getMet', params.searchTerm)
    },
     /**
     * CMS 페이징
     * @created 2021-04-20
     * @author 박재헌
     * @param {Number} currentPage 페이지 Index
     * @commnet currentPage를 부모 Calls 컴포넌트에 전송, 페이징하여 리스트 API 재실행
     */
    setOffset(){
      if(this.currentPage === 1){
        // if(this.searchItem != ''){
        //   this.
        // }else{
          this.$emit('callParent', this.currentPage -1)
        // }
      }else{
        this.$emit('callParent', (this.currentPage-1)*10)
      }
    },
  },
  computed: {
    rows() {
      return this.items.length
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
